import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ResponsiveMedia from "utils/responsive_media";
import { TabContainer, TextTabButtonWrapper, TabButtonText } from "./style";

const Tab = ({ data, onClickEvent, initIndex = 0 }) => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();
    const [active, setActive] = useState(initIndex);

    const handleTab = (index) => {
        setActive(index);
        onClickEvent && onClickEvent(index);
    };
    return (
        <TabContainer>
            {data.map((item, index) => {
                return (
                    <TextTabButtonWrapper key={index} onClick={() => handleTab(index)} active={index === active}>
                        <TabButtonText mobile={isMobile === "true"}>
                            <p>{t(item.title)}</p>
                        </TabButtonText>
                    </TextTabButtonWrapper>
                );
            })}
        </TabContainer>
    );
};

export default Tab;
