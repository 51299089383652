import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

////////////// Canvas

export const SignContainer = styled.div`
    width: 100%;
    height: 100%;
    max-width: ${(props) => props.maxWidth};
    max-height: ${(props) => props.height};
    min-width: 100px;
    min-height: 100px;
    padding: ${(props) => props.padding};
    margin: ${(props) => props.margin};
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
`;

export const CreatePadBox = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: dashed 1px #ddd;
    background-color: #faf7ef;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: ${(props) => (props.mobile ? `14px` : `28px`)};
`;

export const HolderText = styled.div`
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    text-align: center;
    color: rgba(0, 0, 0, 0.25);
    user-select: none;

    ${(props) =>
        props.mobile
            ? `
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.33;
        letter-spacing: -0.54px;
        `
            : `
        font-size: 26px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.65;
        letter-spacing: -0.78px;
        `}

    display: ${(props) => (props.drawing ? "none" : "block")};
`;

export const ResetButton = styled.div`
    width: 122px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 19.5px;
    right: 20px;
    cursor: pointer;

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.48px;
    text-align: center;
    white-space: nowrap;
    color: #713838;
    border-radius: 4px;
    border: solid 1px #713838;

    &:hover {
    }
`;

export const ReturnImageWrapper = styled.div`
    object-fit: contain;
    position: absolute;
    bottom: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    cursor: pointer;
`;

////////////// Image
export const CreateSignContainer = styled.div`
    width: 100%;
    height: 100%;
`;

export const CreateSignCenterBox = styled.div`
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 16px;

    ${(props) =>
        props.mobile
            ? `
    gap: 8px;
    margin: 12px 0 8px;
    `
            : `
    margin: 28px 0 12px;
    `}
`;

export const OutlineButton = styled.div`
    width: ${(props) => (props.mobile ? "62px" : "84px")};
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.42px;
    text-align: center;
    color: #713838;
    white-space: nowrap;
    color: #713838;
    border-radius: 4px;
    border: solid 1px #713838;

    &:hover {
    }
`;

export const CreateSignBox = styled.div`
    ${(props) =>
        props.mobile
            ? `
    // width: 303px;
    height: 184px;
    > div {
        margin: 10px 10px;
    }
    `
            : `
    width: 686px;
    height: 324px;
    > div {
        margin: 16px 16px;
    }
    `}

    border-radius: 8px;
    border: dashed 1px #ddd;
    display: flex;
    align-items: top;
    justify-content: center;
    position: relative;
`;

export const SignTable = styled(Container)`
    max-width: 100%;
    overflow: hidden overlay;
    padding: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    @media (min-width: 576px) {
        .container {
            max-width: 100%;
        }
        max-width: 100% !important;
    }
`;

export const SignTableRow = styled(Row)`
    display: flex;
    align-items: center;
    justify-content: left;
    margin-left: 0;
    gap: 8px;
`;

export const SignTableCol = styled(Col)`
    ${(props) =>
        props.mobile
            ? props.type === "sign"
                ? `
        width: 136px !important;
        height: 60px !important;
        > div {
            height: 100% !important;
        }
        `
                : `
        width: 88px !important;
        height: 57px !important;
        > div {
            width: 42px !important;
            height: 42px !important;
        };
        `
            : `
    width: 157px !important;
    height: 103px !important;
    > div {
        width: 126px !important;
        height: 83px !important;
    };
    `};

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    cursor: pointer;
    border: ${(props) => (props.select ? `solid 2px #cd001a` : `solid 1px #ddd`)};
`;

export const SignImgWrapper = styled.div`
    > img {
        object-fit: contain;
    }
`;

export const InputWrapper = styled.div`
    flex: 1;
`;

export const ErrorMessage = styled.p`
    margin: 4px 5px 0;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: -0.39px;
    text-align: left;
    color: #cd001a;
`;

export const TabContainer = styled.div`
    width: 100%;
    border-bottom: solid 1px #dddddd;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: "0 0 48px";
`;

export const TextTabButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    > div {
        &:nth-child(1) {
            ${(props) =>
                props.active
                    ? `
                color: #713838;
                margin-top: 3px;
                border-bottom: solid 3px #713838;
                > p {
                    font-weight:700;
                }
            `
                    : `  
                color: #999999;
                border-bottom: solid 0px #999999;
            `}
        }
    }
`;

export const TabButtonText = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > p {
        margin: 0;
        padding: 0;
        width: 100%;
        text-align: center;
        font-weight: 500;
        ${(props) =>
            props.mobile
                ? `
            font-size:  14px;
            padding: 0 0 10px;
        `
                : `
            font-size:  20px;
            padding: 0 0 13px;
        `};
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: -0.48px;
        text-align: center;
        white-space: pre-line;
    }
`;
