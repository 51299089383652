// export const SIGNATURE_TITLE = {
//     title : '무료 전자 서명/도장 만들기',
//     desc : '나만의 전자 서명/도장 생성 후\n지금 도뉴에서 사용해보세요.',
// }

// export const SIGN_MENU_TAB = {
//     items: [
//         { title: '서명하기'},
//         { title: '서명 만들기'},
//         { title: '도장 만들기'},
//     ]
// }

// export const SIGN_SIGNATURE_TAB = ['국문', '영어'];
// export const SIGN_STAMP_TAB = ['국문', '한자'];

// export const SIGN_AGREEMENT_LIST = [
//     {
//         color: '#cd001a',
//         head: '[필수]',
//         title: '개인정보 처리 방침',
//         url: process.env.GATSBY_DONUE_PORTAL + '/terms/privacy',
//     },
//     // {
//     //     color: '#707070',
//     //     head: '[선택]',
//     //     title: '마케팅 정보 수신',
//     //     url: process.env.GATSBY_DONUE_PORTAL + '/terms/marketing',
//     // }
// ]

// export const COMPLETE_SEND_EMAIL = {
//     title: '이메일 전송 완료',
//     desc: '생성된 서명과 도장을\n전자계약 도뉴에서 사용해보세요',
//     items: [
//         {
//             title: '닫기',
//         },
//         {
//             title: '무료 체험 시작',
//         }
//     ]
// }

export const SIGNATURE_TITLE = {
    title: "SIGNATURE_TITLE_TITLE",
    desc: "SIGNATURE_TITLE_DESC",
    desc_mobile:"SIGNATURE_TITLE_DESC_MOBILE"
};

export const SIGN_MENU_TAB = {
    items: [{ title: "SIGN_MENU_TAB_ITEM_1_TITLE" }, { title: "SIGN_MENU_TAB_ITEM_2_TITLE" }, { title: "SIGN_MENU_TAB_ITEM_3_TITLE" }]
};

export const SIGN_SIGNATURE_TAB = ["SIGN_SIGNATURE_TAB_1", "SIGN_SIGNATURE_TAB_2"];

export const SIGN_STAMP_TAB = ["SIGN_STAMP_TAB_1", "SIGN_STAMP_TAB_2"];

// const SIGN_AGREEMENT_LIST_ITEM_2_COLOR = '#707070';
// const SIGN_AGREEMENT_LIST_ITEM_2_HEAD = '[Optional]';
// const SIGN_AGREEMENT_LIST_ITEM_2_TITLE = 'Receive Marketing Information';
// const SIGN_AGREEMENT_LIST_ITEM_2_URL = process.env.GATSBY_DONUE_PORTAL + '/terms/marketing';

export const SIGN_AGREEMENT_LIST = [
    {
        color: "#cd001a",
        head: "SIGN_AGREEMENT_LIST_ITEM_1_HEAD",
        title: "SIGN_AGREEMENT_LIST_ITEM_1_TITLE",
        url: process.env.GATSBY_DONUE_PORTAL + "/terms/privacy"
    }
    // {
    //     color: SIGN_AGREEMENT_LIST_ITEM_2_COLOR,
    //     head: SIGN_AGREEMENT_LIST_ITEM_2_HEAD,
    //     title: SIGN_AGREEMENT_LIST_ITEM_2_TITLE,
    //     url: SIGN_AGREEMENT_LIST_ITEM_2_URL,
    // }
];

export const COMPLETE_SEND_EMAIL = {
    title: "COMPLETE_SEND_EMAIL_TITLE",
    desc: "COMPLETE_SEND_EMAIL_DESC",
    items: [
        {
            title: "COMPLETE_SEND_EMAIL_ITEM_1_TITLE"
        },
        {
            title: "COMPLETE_SEND_EMAIL_ITEM_2_TITLE"
        }
    ]
};
