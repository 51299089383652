import styled from "styled-components";

export const ModalContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999999;
    position: fixed;
`;

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

export const SignModalBox = styled.div`
    width: 344px;
    height: auto;
    ${(props) =>
        props.mobile
            ? `
    margin: -50px 0 0;
    // min-height: 545px;
    `
            : `
    // min-height: 601px;
    `}
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    z-index: 2000;
`;

export const SignTitleText = styled.div`
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.48px;
    text-align: center;
    color: #1e1e1e;
    margin-bottom: 12px;
`;

export const SignDescText = styled.div`
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.42px;
    text-align: center;
    color: #1e1e1e;
`;

export const SignImgBox = styled.div`
    ${(props) =>
        props.mobile
            ? `
    width: 296px;
    height: 100px;
    padding: 10px 91px;
    `
            : `
    width: 296px;
    height: 158px;
    padding: 40px 91px;
    `}
    margin: 20px 0;
    border: solid 1px #ddd;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const EmailContainer = styled.div`
    width: 100%;
    text-align: left;
    margin-bottom: 32px;
`;

export const EmailTitleText = styled.div`
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: -0.39px;
    text-align: left;
    color: #1e1e1e;
    margin-bottom: 8px;
`;

export const EmailInput = styled.input.attrs((props) => ({
    type: "email"
}))`
    width: 100%;
    padding: 10px 12px 9px;
    border: solid 1px #ddd;
    outline: none;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.42px;
    text-align: left;
    color: #1e1e1e;
    margin-bottom: 8px;
`;

export const EmailWarnText = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.42px;
    text-align: left;
    color: #cd001a;
    display: ${(props) => (props.warn ? "block" : "none")};
`;

export const TermsContainer = styled.div`
    width: 100%;
`;

export const TermsTitleText = styled.div`
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.39px;
    text-align: left;
    color: #1e1e1e;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AccentText = styled.span`
    color: ${(props) => props.color || "#1e1e1e"};
`;

export const LinkText = styled.p`
    text-decoration: underline;
    color: #1e1e1e;
    cursor: pointer;
    margin: 0 3px;

    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.39px;
    text-align: left;

    &: hover {
        color: #1e1e1e;
    }
`;

export const CheckBox = styled.div`
    width: 20px;
    height: 20px;
    margin: 0 8px 0 0;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    ${(props) =>
        props.checked
            ? `
        border: solid 1.5px #713838;
        background-color: #713838;
        > img {
            display: block;
        }
    `
            : `
        border: solid 1.5px #b5b5b5;
        background-color: #fff;
    `}
`;

export const CheckIcon = styled.img`
    display: none;
    object-fit: contain;
`;

export const AgreementBox = styled.div`
    width: 100%;
    padding: 0 0 20px;
    margin: 10px 0 24px;
`;

export const AgreementWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
`;

export const ButtonBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
`;

///

export const CompleteModalBox = styled.div`
    height: auto;
    ${(props) =>
        props.mobile
            ? `
        margin: -50px 0 0;
        width: 335px;
    `
            : `
        width: 344px;
    `}
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    z-index: 2000;
`;

export const CompleteTitleText = styled.div`
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.42px;
    text-align: center;
    color: #1e1e1e;
    margin-bottom: 12px;
`;

export const CompleteDescText = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.42px;
    text-align: center;
    color: #1e1e1e;
    white-space: pre-line;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 36px;
    width: 100%;
    margin-top: 8px;
    ${(props) => props.hideBtn && `display:none`}
`;

///

export const LoadingProgress = styled.div`
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
`;

// notice modal

export const NoticeWrapper = styled.div`
    width: 344px;
    padding: 24px 24px 16px 24px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 9999;

    ${(props) =>
        props.mobile
            ? `
    margin: -50px 0 0;
    `
            : ``}

    .title {
        color: #713838;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 16px;
        line-height: 1.18;
        letter-spacing: -0.66px;
    }

    .time {
        padding: 4px 10px;
        border-radius: 18px;
        color: #713838;
        background-color: #ede4dd;
        margin-bottom: 20px;
    }
    .text1 {
        font-size: 14px;
        margin-bottom: 20px;
        color: #713838;
        line-height: 1.43;
        letter-spacing: -0.42px;
    }

    .text2 {
        font-size: 12px;
        margin-bottom: 0px;
        line-height: 1.5;
        letter-spacing: -0.36px;
    }
    .blog_btn {
        width: 100%;
        height: 48px;
        font-size: 16px;
        color: #fff;
    }
`;

export const NoticeDivider = styled.div`
    width: 0;
    height: 16px;
    border-left: solid 1px rgba(0, 0, 0, 0.3);
`;

export const NoticeContents = styled.div`
    background: #faf7ef;
    width: 100%;
    flex: 1;
    padding: 24px 20px 32px 20px;
    text-align: center;
`;

export const Button = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 4px;

    ${(props) =>
        props.left &&
        `
        font-size: 14px;
        line-height: 1.83;
        letter-spacing: -0.36px;
        text-align: center;
        color: #999999;

        &:hover {
            background-color: #efefef;
        }

        &:active {
            background-color: #dddddd;
        }

    `};

    ${(props) =>
        props.right &&
        `
        font-size: 14px;
        line-height: 1.83;
        letter-spacing: -0.36px;
        text-align: center;
        color: #1e1e1e;

        &:hover {
            background-color: #efefef;
        }

        &:active {
            background-color: #b5b5b5;
        }
    `};
`;
