import React, { useEffect, useState } from "react";
import { InputContainer, Input, InputMaxLength, InnputLength } from './style';

const UnderLineInput = (props) => {
    const isMobile = props.mobile;

    const width = props.width;
    const height = props.height;
    const max = props.max;
    const holder = props.holder;
    const value = props.value;
    
    const [inputLength, setInputLength] = useState(value.length);

    const handleInput = (event) => {
        if(event.target.value.length > 14) {return}
        
        props.onChange && props.onChange(event.target.value);
    }

    useEffect(() => {
        setInputLength(value.length);
    }, [value])

    return (
        <InputContainer width={width} height={height} >
            <Input mobile={isMobile} placeholder={holder} value={value} onChange={handleInput}/>
            <InputMaxLength><InnputLength active={inputLength>0}>{inputLength}</InnputLength>/{max}</InputMaxLength>
        </InputContainer>
    )
}

export default UnderLineInput;
