import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { SignatureActions } from "redux/actions";

import ResponsiveMedia from "utils/responsive_media";
import { Container, Box } from "components/style/layoutStyle";
import { SquareButton } from "components/button";
import Description from "../../parts/description/description";
import { SIGNATURE_TITLE, SIGN_MENU_TAB } from "../../../constants/data/service_signature";
import { SignContainer } from "./style";
import Tab from "./tab";
import SignCanvas from "./signCanvas";
import SignImage from "./signImage";
import SignFormModal from "../../parts/modal/signFormModal";
import SignSendCompleteModal from "../../parts/modal/signSendCompleteModal";
import { useTranslation } from "react-i18next";

const Signature = ({ signExist, sendProcess }) => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();
    const [activeTab, setActiveTab] = useState(0);

    const handleSignTab = (tab) => {
        setActiveTab(tab);
        SignatureActions.setExist(false);
    };

    const createSignStamp = () => {
        if (!signExist) return;
        SignatureActions.setProcess(1);
    };

    useEffect(() => {
        if (sendProcess === -1) {
            SignatureActions.setProcess(0);
            window.location.reload();
        }
    }, [sendProcess]);

    return (
        <Container padding={isMobile === "true" ? "64px 20px 40px" : "80px 20px 260px"}>
            <Box flexDirection={"column"}>
                <Description
                    type={"main"}
                    textGap={isMobile === "true" ? "0 0 8px" : "0 0 14px"}
                    whiteSpace={isMobile === "true" ? "pre-line" : "nowrap"}
                    title={t(SIGNATURE_TITLE.title)}
                    desc={isMobile === "true" ? t(SIGNATURE_TITLE.desc_mobile) : t(SIGNATURE_TITLE.desc)}
                    titleGap={"0px 0px 14px"}
                />
                <SignContainer
                    maxWidth={isMobile === "true" ? "100%" : "750px"}
                    maxHeight={isMobile === "true" ? "303px" : "519px"}
                    padding={isMobile === "true" ? "20px 16px" : "36px 32px 40px"}
                    margin={isMobile === "true" ? "40px 0 30px" : "30px 0 40px"}
                >
                    <Tab data={SIGN_MENU_TAB.items} onClickEvent={handleSignTab} />
                    {activeTab === 0 && <SignCanvas />}
                    {activeTab === 1 && <SignImage state={"sign"} />}
                    {activeTab === 2 && <SignImage state={"stamp"} />}
                </SignContainer>
                <SquareButton
                    width={isMobile === "true" ? "100%" : "340px"}
                    height={isMobile === "true" ? "46px" : "68px"}
                    fontSize={isMobile === "true" ? "16px" : "22px"}
                    color={signExist ? "#fff" : "#b5b5b5"}
                    hColor={signExist ? "#fff" : "#b5b5b5"}
                    aColor={signExist ? "#fff" : "#b5b5b5"}
                    bgColor={signExist ? "#713838" : "#ddd"}
                    hBgColor={signExist ? "#451818" : "#ddd"}
                    aBgColor={signExist ? "#451818" : "#ddd"}
                    text={t("CREATE_SIGNATURE_BTN_TEXT")}
                    onClickEvent={() => createSignStamp()}
                />
            </Box>
            {sendProcess === 1 && <SignFormModal activeTab={activeTab} />}
            {sendProcess === 2 && <SignSendCompleteModal />}
        </Container>
    );
};

export default connect(
    (state) => ({
        signExist: state.signature.exist,
        sendProcess: state.signature.process
    }),
    null
)(Signature);
