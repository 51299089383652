import React, { useEffect, useState } from "react";
import Select from "components/select/select";
import UnderLineInput from "components/input/underLineInput";
import axios from "axios";
import { SignatureActions } from "redux/actions";
import ResponsiveMedia from "utils/responsive_media";

import { CreateSignContainer, CreateSignCenterBox, OutlineButton, CreateSignBox, SignTable, SignTableRow, SignTableCol, SignImgWrapper, InputWrapper, ErrorMessage } from "./style";
import { SIGN_SIGNATURE_TAB, SIGN_STAMP_TAB } from "../../../constants/data/service_signature";
import { MAKE_SIGNATURE_BTN_TEXT, SIGNATURE_INPUT_ERROR_CH, SIGNATURE_INPUT_ERROR_EN, SIGNATURE_INPUT_ERROR_KO, SIGNATURE_INPUT_PLACEHOLDER, SIGNATURE_MOBILE_INPUT_PLACEHOLDER } from "constants/data/signature";
import { useTranslation } from "react-i18next";

export default function SignImage({ state }) {
    const { t, i18n } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    // sign - stamp
    const url = state === "sign" ? "makeSignature" : "makeStamp";

    // 0 = 국문 / 1 = 영문 or 한자
    const [langIndex, setLangIndex] = useState(0);

    const langs = ["kr", state === "sign" ? "en" : "zh"];

    const [inputText, setInputText] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [imgIndex, setImgIndex] = useState(null);
    const [imgs, setImgs] = useState(null);

    const handleInputText = (value) => {
        setInputText(value);
    };

    const handleSelectValue = (index) => {
        setLangIndex(index);
    };

    const handleImage = (index) => {
        setImgIndex(index);
        SignatureActions.setExist(true);
        SignatureActions.setImage(imgs[index]);
    };

    const createSignImages = async () => {
        const regExpKo = state === "sign" ? /^[ㄱ-ㅎ|가-힣().\s]+$/i : /^[ㄱ-ㅎ|가-힣()]+$/i;
        const regExpEn = state === "sign" ? /^[a-zA-Z .]*$/i : /^[a-zA-Z]*$/i;

        const signatureText = inputText.trim();

        if (langs[langIndex] === "kr" && !regExpKo.test(signatureText)) {
            setErrorMessage(t("SIGNATURE_INPUT_ERROR_KO"));
            return;
        }

        if (langs[langIndex] === "en" && !regExpEn.test(signatureText)) {
            setErrorMessage(t("SIGNATURE_INPUT_ERROR_EN"));
            return;
        }

        if (langs[langIndex] === "zh" && !/[\u2e80-\u2eff\u31c0-\u31ef\u3200-\u32ff\u3400-\u4dbf\u4e00-\u9fbf\uf900-\ufaff]/.test(signatureText)) {
            setErrorMessage(t("SIGNATURE_INPUT_ERROR_CH"));
            return;
        }

        if (signatureText.length === 0) {
            setImgIndex(null);
            setImgs(null);
            return;
        }

        setErrorMessage(null);
        let data = {
            text: signatureText,
            lang: langs[langIndex]
        };

        try {
            await axios
                .post(`${process.env.GATSBY_DONUE_API_HOST}/signature/${url}`, JSON.stringify(data), {
                    headers: {
                        "Content-type": `application/json`
                    }
                })
                .then((res) => {
                    const payload = res.data.payload;
                    let arr = [];

                    if (payload.signatureList) arr = arr.concat(payload.signatureList);
                    if (payload.personalStamps) arr = arr.concat(payload.personalStamps);
                    if (payload.corpStamps) arr = arr.concat(payload.corpStamps);
                    setImgs(arr);
                });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (imgs) {
            SignatureActions.setImage(imgs[0]);
        }
    }, [imgs]);

    useEffect(() => {
        const resetAllData = () => {
            setLangIndex(0);
            setInputText("");
            setImgIndex(null);
            setImgs(null);
            setErrorMessage(null);
        };
        resetAllData();
    }, [state]);

    useEffect(() => {
        setErrorMessage(null);
    }, [langIndex]);

    useEffect(() => {
        setLangIndex(i18n.language === "ko" ? 0 : 1);
    }, [i18n.language]);

    return (
        <CreateSignContainer>
            <CreateSignCenterBox mobile={isMobile}>
                <Select onChangeEvent={(value) => handleSelectValue(value)} initVal={i18n.language === "ko" ? 0 : 1} data={state === "sign" ? SIGN_SIGNATURE_TAB : SIGN_STAMP_TAB} width={isMobile ? "63px" : "67px"} />
                <InputWrapper>
                    <UnderLineInput mobile={isMobile} onChange={handleInputText} value={inputText} max={14} holder={isMobile === "true" ? t("SIGNATURE_INPUT_PLACEHOLDER") : t("SIGNATURE_INPUT_PLACEHOLDER")} />
                    {errorMessage !== null && <ErrorMessage>{errorMessage}</ErrorMessage>}
                </InputWrapper>
                <OutlineButton mobile={isMobile} onClick={() => createSignImages()}>
                    {t("MAKE_SIGNATURE_BTN_TEXT")}
                </OutlineButton>
            </CreateSignCenterBox>
            <CreateSignBox mobile={isMobile}>
                <SignTable>
                    <SignTableRow xs={4}>
                        {imgs &&
                            imgs.map((img, index) => {
                                return (
                                    <SignTableCol mobile={isMobile} type={state} key={index} select={imgIndex !== null && imgIndex === index} onClick={() => handleImage(index)}>
                                        <SignImgWrapper>
                                            <img src={img} alt="전자 서명/도장 만들기" style={{ width: "100%", height: "100%", margin: "0" }} />
                                            {/* <Img fluid={img} alt='전자 서명/도장 만들기' style={{width:'100px', height:'100px'}} /> */}
                                        </SignImgWrapper>
                                    </SignTableCol>
                                );
                            })}
                    </SignTableRow>
                </SignTable>
            </CreateSignBox>
        </CreateSignContainer>
    );
}
