import React, { useEffect } from "react";
import ResponsiveMedia from "utils/responsive_media";
import { SignatureActions } from "redux/actions";
import { COMPLETE_SEND_EMAIL } from "../../../constants/data/service_signature";
import { ModalContainer, ModalOverlay, CompleteModalBox, CompleteTitleText, CompleteDescText, ButtonBox } from "./style";
import { SquareButton } from "components/button";
import MoveToSignUp from "utils/move_to_sign_up";
import { checkIsLogin } from "utils/common";
import { useTranslation } from "react-i18next";

const SignSendCompleteModal = () => {
    const { t } = useTranslation();

    const { isMobile } = ResponsiveMedia();

    const closeModal = () => {
        SignatureActions.setProcess(-1);
    };

    const handleMoveToSignUp = () => {
        MoveToSignUp();
    };

    const isLogin = checkIsLogin();

    useEffect(() => {
        document.body.style.overflow = "hidden";
        document.body.style.touchAction = "none";
        return () => {
            document.body.style.overflow = "overlay";
            document.body.style.touchAction = "";
        };
    }, []);

    return (
        <ModalContainer>
            <ModalOverlay />
            <CompleteModalBox mobile={isMobile}>
                <CompleteTitleText>{t(COMPLETE_SEND_EMAIL.title)}</CompleteTitleText>
                <CompleteDescText>{t(COMPLETE_SEND_EMAIL.desc)}</CompleteDescText>
                <ButtonBox style={{ margin: "20px 0 0" }}>
                    <SquareButton fontSize={"16px"} bgColor={"#fff"} hBgColor={"#efefef"} aBgColor={"#dddddd"} color={"#1e1e1e"} hColor={"#1e1e1e"} aColor={"#1e1e1e"} text={t("SIGN_COMPLETE_MODAL_TEXT")} onClickEvent={() => closeModal()} />

                    {!isLogin && (
                        <SquareButton
                            fontSize={"16px"}
                            bgColor={"#fff"}
                            hBgColor={"#efefef"}
                            aBgColor={"#b5b5b5"}
                            color={"#1e1e1e"}
                            hColor={"#1e1e1e"}
                            aColor={"#1e1e1e"}
                            text={t("COMPLETE_SEND_EMAIL_ITEM_2_TITLE")}
                            onClickEvent={() => handleMoveToSignUp()}
                        />
                    )}
                </ButtonBox>
            </CompleteModalBox>
        </ModalContainer>
    );
};

export default SignSendCompleteModal;
