import React, { useCallback, useRef, useState } from "react";
import { SignatureActions } from "redux/actions";
import ResponsiveMedia from "utils/responsive_media";
import SignatureCanvas from "react-signature-canvas";
import { IC_RETURN } from "constants/media/icon";
import Image from "components/image/image";
import { CreatePadBox, HolderText, ResetButton, ReturnImageWrapper } from "./style";
import { MAKE_AGAIN_SIGNATURE_BTN_TEXT, SIGNATURE_CANVAS_TEXT } from "constants/data/signature";
import { useTranslation } from "react-i18next";

const SignCanvas = ({ onApply }) => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    // const canvasSize = isMobile ? { width: '100%', height: 220 } : { width: 686, height: 366 };

    const [isDrawing, setIsDrawing] = useState(false);
    const canvasRef = useRef(null);
    const padRef = useRef(null);

    const setRef = useCallback(
        (ref) => {
            if (!ref) return;

            canvasRef.current = ref;
            padRef.current = ref.getSignaturePad();
        },
        [canvasRef, padRef]
    );

    const handleBegin = useCallback(() => {
        setIsDrawing(true);
        SignatureActions.setExist(true);
    }, []);

    const handleClear = () => {
        padRef.current.clear();
        setIsDrawing(false);
        SignatureActions.setExist(false);
    };

    const handleEnd = useCallback(() => {
        const result = canvasRef.current.getTrimmedCanvas().toDataURL("image/png");
        SignatureActions.setImage(result);
    });

    return (
        <CreatePadBox mobile={isMobile}>
            <SignatureCanvas ref={setRef} onBegin={handleBegin} onEnd={handleEnd} canvasProps={{ style: { width: "100%", height: isMobile ? "220px" : "366px" } }} />
            <HolderText mobile={isMobile} drawing={isDrawing}>
                {t("SIGNATURE_CANVAS_TEXT")}
            </HolderText>
            {isDrawing && (
                <ReturnImageWrapper onClick={handleClear}>
                    <Image filename={IC_RETURN} alt={"전자서명 다시하기 버튼"} />
                </ReturnImageWrapper>
            )}
        </CreatePadBox>
    );
};

export default React.memo(SignCanvas);
