import styled from "styled-components";

export const InputContainer = styled.div`
    width: ${(props) => props.width || "100%"};
    height: ${(props) => props.height || "32px"};
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: solid 1px #ddd;
`;

export const Input = styled.input`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    border: none;
    outline: none;
    margin: ${(props) => (props.mobile ? "0" : "0 8px")};

    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.42px;
    text-align: left;
    color: #1e1e1e;
`;

export const InputMaxLength = styled.div`
    width: 29px;
    margin: 0 8px;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.42px;
    text-align: right;
    color: #b5b5b5;
`;

export const InnputLength = styled.div`
    padding: 0;
    color: ${(props) => (props.active ? `#1e1e1e` : `#b5b5b5`)};
`;

/// search

export const SearchbarContainer = styled.div`
    width: 100%;
    position: relative;
    padding: 20px 0 0;
`;

export const SearchbarBox = styled.div`
    width: auto;
    height: 40px;
    padding: 2px 15px;
    display: flex;
    align-items: center;
    border-radius: 80px !important;
    border: solid 1px #999;
    box-shadow: none !important;

    &:hover,
    &:focus-within {
        border: solid 1px #1e1e1e !important;
    }
`;

export const SearchbarInput = styled.input`
    width: 100%;
    flex: 1;
    border: none;
    outline: none;

    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43px;
    letter-spacing: -0.42px;
    text-align: left;
`;

export const IconWrapper = styled.div`
    width: ${(props) => props.width || "24px"};
    height: ${(props) => props.height || "24px"};
    margin: ${(props) => props.margin};
    cursor: pointer;
`;

export const ErrorMessage = styled.p`
    padding-left: 15px;
    /* position: absolute;
    bottom: 0px;
    left: 20px; */
    margin: 4px 0 0 0;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: -0.39px;
    text-align: left;
    color: #cd001a;
`;
