import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SignatureActions } from "redux/actions";
import axios from "contentful-management/node_modules/axios";
import ResponsiveMedia from "utils/responsive_media";

import {
    ModalContainer,
    ModalOverlay,
    SignModalBox,
    SignTitleText,
    SignDescText,
    SignImgBox,
    EmailContainer,
    EmailTitleText,
    EmailInput,
    EmailWarnText,
    TermsContainer,
    AgreementWrapper,
    CheckBox,
    TermsTitleText,
    AgreementBox,
    AccentText,
    LinkText,
    ButtonBox,
    LoadingProgress
} from "./style";
import { SquareButton } from "components/button";
import Image from "components/image/image";
import { SIGN_AGREEMENT_LIST, SIGN_MENU_TAB } from "../../../constants/data/service_signature";
import { IC_TERMS_CHECK } from "../../../constants/media/icon";
import { GaEventTracter } from "../../../utils/gaTracker";
import { useTranslation } from "react-i18next";

const SignFormModal = ({ signImage, activeTab }) => {
    const { t } = useTranslation();

    const { isMobile } = ResponsiveMedia();

    const [agreeAll, setAgreeAll] = useState(false);
    const [agree, setAgree] = useState([]);
    const [email, setEmail] = useState("");
    const [warning, setWarning] = useState(false);
    const [activateSend, setActivateSend] = useState(false);
    const [isSending, setIsSending] = useState(false);

    useEffect(() => {
        document.body.style.overflow = "hidden";
        document.body.style.touchAction = "none";
        return () => {
            document.body.style.overflow = "overlay";
            document.body.style.touchAction = "";
        };
    }, []);

    const handleEmail = (event) => {
        setEmail(event.target.value);
    };

    const handleSingleCheck = (index) => {
        if (!agree.includes(index)) {
            setAgree([...agree, index]);
        } else {
            setAgree(agree.filter((item) => item !== index));
        }
    };

    const handleAllCheck = (event) => {
        if (!agreeAll) {
            const checkArray = [];
            SIGN_AGREEMENT_LIST.forEach((item, index) => checkArray.push(index));
            setAgree(checkArray);
        } else {
            setAgree([]);
        }
        setAgreeAll(!agreeAll);
    };

    const openLink = (url) => {
        window.open(url, "_blank");
    };

    const closeModal = () => {
        SignatureActions.setProcess(0);
    };

    const sendMail = () => {
        if (!activateSend) return;
        setIsSending(true);
        try {
            axios
                .post(
                    `${process.env.GATSBY_DONUE_API_HOST}/signature/sendSignatureMail`,
                    JSON.stringify({
                        email: email,
                        signImage: signImage
                    }),
                    {
                        headers: { "Content-type": `application/json` }
                    }
                )
                .then((res) => {
                    sentComplete();
                });
        } catch (error) {
            console.log(error);
            sentComplete();
            alert("전송에 실패 했습니다.");
        }
    };

    const sentComplete = () => {
        let emailArr = email.split("@");
        GaEventTracter("무료 전자 서명/도장 만들기 > " + SIGN_MENU_TAB.items[activeTab].title, emailArr[0] + `${emailArr.length > 1 ? "(" + emailArr[1] + ")" : ""}`);
        setIsSending(false);
        SignatureActions.setProcess(2);
    };

    useEffect(() => {
        setAgreeAll(SIGN_AGREEMENT_LIST.length === agree.length ? true : false);
    }, [agree]);

    useEffect(() => {
        function validateEmail() {
            const validate = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
            setWarning(!validate.test(email));
        }
        if (email === "") {
            setWarning(false);
        } else {
            validateEmail();
        }
    }, [email]);

    useEffect(() => {
        if (agree.includes(0) && !warning && email !== "") {
            setActivateSend(true);
        } else {
            setActivateSend(false);
        }
    }, [warning, agree]);

    return (
        <ModalContainer>
            <ModalOverlay />
            <SignModalBox mobile={isMobile}>
                <SignTitleText>{t("SIGN_FORM_MODAL_TITLE")}</SignTitleText>
                <SignDescText>{t("SIGN_FORM_MODAL_DESC")}</SignDescText>
                <SignImgBox mobile={isMobile}>
                    <img src={signImage} alt="sign_img" style={isMobile === "true" ? { width: "287px", height: "100px", margin: "0", objectFit: "contain" } : { width: "296px", height: "158px", margin: "0", objectFit: "contain" }} />
                </SignImgBox>
                <EmailContainer>
                    <EmailTitleText>{t("SIGN_FORM_MODAL_INPUT_TITLE")}</EmailTitleText>
                    <EmailInput placeholder={"ex) example@donue.co.kr"} value={email} onChange={handleEmail} />
                    <EmailWarnText warn={warning}>{t("SIGN_FORM_MODAL_INPUT_ERROR_TEXT")}</EmailWarnText>
                </EmailContainer>
                <TermsContainer>
                    <AgreementBox>
                        {SIGN_AGREEMENT_LIST.map((list, index) => {
                            return (
                                <AgreementWrapper key={index}>
                                    <CheckBox onClick={() => handleSingleCheck(index)} checked={agree.includes(index) ? true : false}>
                                        {agree.includes(index) && <Image filename={IC_TERMS_CHECK} alt="terms check" style={{ width: "20px", height: "20px" }} />}
                                    </CheckBox>
                                    <TermsTitleText>
                                        <AccentText color={list.color}>{t(list.head)} </AccentText>
                                        <LinkText onClick={() => openLink(list.url)}>{t(list.title)}</LinkText> {t("SIGN_FORM_MODAL_AGREE_TEXT")}
                                    </TermsTitleText>
                                </AgreementWrapper>
                            );
                        })}
                    </AgreementBox>
                    <ButtonBox>
                        <SquareButton
                            fontSize={"16px"}
                            bgColor={"#efefef"}
                            hBgColor={"#dddddd"}
                            aBgColor={"#b5b5b5"}
                            color={"#1e1e1e"}
                            hColor={"#1e1e1e"}
                            aColor={"#1e1e1e"}
                            text={t("SIGN_FORM_MODAL_BTN_TEXT1")}
                            fontFamily={"normal"}
                            onClickEvent={() => closeModal()}
                        />

                        <SquareButton
                            fontSize={"16px"}
                            bgColor={activateSend ? "#713838" : "#ddd"}
                            hBgColor={activateSend ? "#713838" : "#ddd"}
                            aBgColor={activateSend ? "#451818" : "#ddd"}
                            color={activateSend ? "#fff" : "#b5b5b5"}
                            hColor={activateSend ? "#fff" : "#b5b5b5"}
                            aColor={activateSend ? "#fff" : "#b5b5b5"}
                            text={t("SIGN_FORM_MODAL_BTN_TEXT2")}
                            fontFamily={"normal"}
                            onClickEvent={() => sendMail()}
                        />
                    </ButtonBox>
                </TermsContainer>
            </SignModalBox>

            {isSending && <LoadingProgress>{/* <CircularProgress color='inherit' /> */}</LoadingProgress>}
        </ModalContainer>
    );
};

export default connect(
    (state) => ({
        signImage: state.signature.image
    }),
    null
)(SignFormModal);
